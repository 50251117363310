import React from "react"
import { connect } from "react-redux"
import { getItemList } from "../../store/actions/metaActions"
import { PageHeader } from "../shared/pageHeader"
import { ItemsStockList } from "./reports"
import ItemSearchForm from "../forms/ItemSearchForm"
import { PrimaryButton } from "@fluentui/react"
import _ from "lodash"
import dayjs from "dayjs";

class DashboardPage extends React.Component {

    componentDidMount() {
        const { getItemList } = this.props
        getItemList()
    }

    onSubmit = () => {

    }

    onDownload = () => {

        const { reports } = this.props
        const itemsStock = _.orderBy(reports.itemsStock.list || [], (item) => item.itemName.toLowerCase());

        let data = [
            ["Item Name", "Closing Stock"]
        ];

        itemsStock.forEach(item => {
            data.push([`${item.itemName}/${item.size}/${item.grade}/${item.make || ""}`, `${(item.calculateByNop ? parseInt(item.balanceNop) : parseFloat(item.balanceSize)) + parseInt(item.purchasedStock)}${item.calculateByNop ? "" : " Kgs"}`])
        })

        const ws = window.XLSX.utils.aoa_to_sheet(data);

        // Create a new workbook and append the worksheet
        const wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Generate an Excel file and trigger the download
        const excelBuffer = window.XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const fileBlob = new Blob([excelBuffer], { type: "application/octet-stream" });

        // Use FileSaver to save the file
        window.saveAs(fileBlob, `stock-closing-${dayjs().format("DD-MMM-YYYY")}.xlsx`);
    }

    render() {
        const { history } = this.props
        return (
            <>
                <PageHeader
                    title="Dashboard"
                    actions={[]}
                    searchComponent={
                        <div style={{ display: "flex", gap: '8px' }}>
                            <ItemSearchForm
                                onSubmit={this.onSubmit}
                            />
                            <PrimaryButton iconProps={{ iconName: 'ExcelDocument' }} type="button" onClick={this.onDownload}>Download</PrimaryButton>
                        </div>
                    }
                />
                <ItemsStockList history={history} />
            </>
        )
    }
}

const mapStateToProps = ({ items, reports }) => ({ items, reports })
const mapDispatchToProps = () => ({
    getItemList
})

export default connect(mapStateToProps, mapDispatchToProps())(DashboardPage)